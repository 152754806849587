import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RailSidenavComponent } from './components/rail-sidenav/rail-sidenav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { PxPipe } from './pipes/px.pipe';
import { DrawerRailModule } from 'angular-material-rail-drawer';
import { DelayedHoverDirective } from './directives/delayed-hover.directive';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DecToStrPipe } from '@shared/pipes/map-data.pipe';
import { MatButtonLoadingDirective } from '@shared/directives/mat-button-loading.directive';
import { IterablePipe } from '@shared/pipes/iterable.pipe';
import { DndTableComponent } from '@shared/components/dnd-table/dnd-table.component';
import { DndColumnComponent } from '@shared/components/dnd-column/dnd-column.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexModule } from '@angular/flex-layout';
import { LineChartComponent } from '@shared/components/line-chart/line-chart.component';
import { PieChartComponent } from '@shared/components/pie-chart/pie-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { TranslateListPipe } from '@shared/pipes/translate-list.pipe';
import { DecToStrListPipe } from '@shared/pipes/map-data-list.pipe';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { SelectComponent } from '@shared/components/popup/select/select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatInputModule } from '@angular/material/input';
import { TakeFocusDirective } from '@shared/directives/take-focus.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatRippleModule } from '@angular/material/core';
import { TableComponent } from '@shared/components/table/table.component';
import { FilterButtonComponent } from '@shared/components/filter/components/filter-button/filter-button.component';
import { FilterOptionComponent } from '@shared/components/filter/components/filter-option/filter-option.component';
import { FilterGroupComponent } from '@shared/components/filter/components/filter-group/filter-group.component';
import { SearchPopupHandlerComponent } from '@shared/components/popup/base/search-popup-handler.component';
import { PopupComponent } from '@shared/components/popup/base/popup.component';
import { TableCellDirective } from '@shared/directives/table-cell.directive';
import { EmptyPageComponent } from '@shared/components/empty-page/empty-page.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { InfoSectionComponent } from '@shared/components/info-section/info-section.component';
import { JobRefToFieldPipe } from '@shared/pipes/job-ref-to-field.pipe';
import { MobileUserRefToFieldPipe } from '@shared/pipes/mobile-user-ref-to-field.pipe';
import { CompanyRefToFieldPipe } from '@shared/pipes/company-ref-to-field.pipe';
import { StatusTagComponent } from '@shared/components/status-tag/status-tag.component';
import { StatusTagPipe } from '@shared/pipes/status-tag.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FilterComponent } from '@shared/components/filter/components/filter/filter.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatchMenuComponent } from '@shared/menus/match-menu/match-menu.component';
import { MatchMoveMenuComponent } from '@shared/menus/match-move-menu/match-move-menu.component';
import { MenuComponent } from '@shared/menus/menu/menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { FnPipe } from '@shared/pipes/fn.pipe';
import { ApplicationMenuComponent } from './menus/application-menu/application-menu.component';
import { ApplicationMoveMenuComponent } from './menus/application-move-menu/application-move-menu.component';
import { UnixTimestampPickerComponent } from './components/forms/unix-timestamp-picker/unix-timestamp-picker.component';
import { ConfirmDialogComponent } from '@shared/components/dialog/confirm/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MobileUserMenuComponent } from './menus/mobile-user-menu/mobile-user-menu.component';
import { RememberSortDirective } from '@shared/directives/remember-sort.directive';

@NgModule({
  declarations: [
    DecToStrListPipe,
    TranslateListPipe,
    LineChartComponent,
    PieChartComponent,
    DndTableComponent,
    DndColumnComponent,
    RailSidenavComponent,
    PxPipe,
    DelayedHoverDirective,
    DecToStrPipe,
    MatButtonLoadingDirective,
    IterablePipe,
    InfoCardComponent,
    SearchPopupHandlerComponent,
    PopupComponent,
    SelectComponent,
    TableComponent,
    FilterButtonComponent,
    FilterOptionComponent,
    FilterGroupComponent,
    TakeFocusDirective,
    TableCellDirective,
    EmptyPageComponent,
    InfoSectionComponent,
    JobRefToFieldPipe,
    MobileUserRefToFieldPipe,
    CompanyRefToFieldPipe,
    StatusTagComponent,
    StatusTagPipe,
    FilterComponent,
    MatchMenuComponent,
    MatchMoveMenuComponent,
    MenuComponent,
    FnPipe,
    ApplicationMenuComponent,
    ApplicationMoveMenuComponent,
    UnixTimestampPickerComponent,
    ConfirmDialogComponent,
    MobileUserMenuComponent,
    RememberSortDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    DrawerRailModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    TranslateModule.forChild(),
    DragDropModule,
    FlexModule,
    NgChartsModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatInputModule,
    NgxSkeletonLoaderModule,
    MatRippleModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMenuModule,
    MatDialogModule,
  ],
  exports: [
    RailSidenavComponent,
    CommonModule,
    DecToStrPipe,
    MatButtonLoadingDirective,
    IterablePipe,
    DndTableComponent,
    LineChartComponent,
    PieChartComponent,
    InfoCardComponent,
    SelectComponent,
    FilterButtonComponent,
    TableComponent,
    TableCellDirective,
    JobRefToFieldPipe,
    MobileUserRefToFieldPipe,
    CompanyRefToFieldPipe,
    StatusTagComponent,
    StatusTagPipe,
    MatchMenuComponent,
    FnPipe,
    ApplicationMenuComponent,
    UnixTimestampPickerComponent,
    ConfirmDialogComponent,
    MobileUserMenuComponent,
  ],
  providers: [JobRefToFieldPipe, MobileUserRefToFieldPipe, CompanyRefToFieldPipe],
})
export class SharedModule {}
